<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <h2>Brothers and Sisters</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-btn color="primary" class="mr-4 text--white" @click="openDialog()">
          Add Info...
        </v-btn>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item="row">
            <tr>
              <td nowrap>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="updateBS(row.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="deleteBS(row.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td>

              <td>{{ row.item.Name }}</td>
              <td>{{ row.item.Age }}</td>
              <td>{{ row.item.School }}</td>
              <td>{{ row.item.EdAtt }}</td>
              <td>{{ row.item.Emp }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :disabled="!valid" dark text @click="savechanges()">
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="12" height="200"></v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="Name"
                            label="Name"
                            outlined
                            dense
                            required
                            :rules="[vv.init(this.Name), vv.required()]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="Age"
                            type="number"
                            :min="0"
                            label="Age"
                            outlined
                            dense
                            required
                            :rules="[
                              vv.init(this.Age),
                              vv.requiredNumber(),
                              vv.digit_range(0, 99),
                            ]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="School"
                            label="School"
                            outlined
                            dense
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="EdAtt"
                            label="Educational Attainment"
                            outlined
                            dense
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="Emp"
                            label="Employment / Business Agency"
                            outlined
                            dense
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="12"> </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6"> </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import vldn from "@/js/validation";
export default {
  name: "Bradsis",
  data: () => ({
    dialog: false,
    valid: true,
    formTitle: "",
    ID: 0,
    Name: "",
    Age: 0,
    School: "",
    EdAtt: "",
    Emp: "",
    headers: [
      {
        text: "action",
        align: "start",
        sortable: false,
        value: "action",
      },
      {
        text: "Name",
        sortable: false,
        value: "name",
      },
      { text: "Age", value: "calories" },
      { text: "School", value: "fat" },
      { text: "Educational Attainment", value: "carbs" },
      { text: "Employment/Business Agency", value: "protein" },
    ],
    desserts: [],
    vv: vldn.methods,
  }),
  mounted() {
    this.$api.methods.init();
    this.getbs();
  },
  methods: {
    openDialog() {
      this.ID = 0;
      this.dialog = true;
      this.formTitle = "Add Brothers or Sisters";
    },
    updateBS(item) {
      this.formTitle = "Edit Brothers or Sisters";
      this.ID = item.ID;
      this.Name = item.Name;
      this.Age = item.Age;
      this.School = item.School;
      this.EdAtt = item.EdAtt;
      this.Emp = item.Emp;
      this.dialog = true;
    },
    deleteBS(item) {
      item.ID;
      this.$axios({
        method: "post",
        url: process.env.VUE_APP_BASE_URL + "guid/deletebs",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
        data: {
          ID: item.ID,
        },
      })
        .then((response) => {
          if (response) {
            this.getbs();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    getbs() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "guid/bs",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.desserts = response.data;
            //this.desserts.push(response.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "guid/updatebs",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            ID: this.ID,
            Name: this.Name,
            Age: this.Age,
            School: this.School,
            EdAtt: this.EdAtt,
            Emp: this.Emp,
          },
        })
          .then((response) => {
            if (response) {
              this.ID = 0;
              this.Name = "";
              this.Age = 0;
              this.School = "";
              this.EdAtt = "";
              this.Emp = "";
              this.formTitle = "Add Brothers or Sisters";
              this.dialog = false;
              this.getbs();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
  },
};
</script>

<style>
.container {
  max-width: 80%;
}
</style>